import { timeout } from 'widgets/toolbox/util';
import { getJSONByUrl } from 'widgets/toolbox/ajax';
import {
    appendParamsToUrl,
    getUrlParams,
    removeParamFromURL
} from 'widgets/toolbox/util';

/**
 * @typedef {ReturnType<typeof import('widgets/search/RefinementsPanel').default>} RefinementsPanelBase
 */

/**
 * @description Extend RefinementsPanel implementation
 * @param {RefinementsPanelBase} RefinementsPanelBase Base widget for extending
 * @returns {typeof RefinementsPanel} RefinementsPanel extending widget
 */
export default function (RefinementsPanelBase) {
    /**
     * @category widgets
     * @subcategory search
     * @class RefinementsPanel
     * @augments RefinementsPanelBase
     */
    class RefinementsPanel extends RefinementsPanelBase {
        /**
         * @description Overloaded Get Refinements Model
         * - `ratings` attribute mustache rendering support with stars
         * @returns {void}
         */
        getRefinementsModel() {
            super.getRefinementsModel();

            this.model.starsRating = this.getStarsRatingFunction();
        }

        /**
         * @description Mustache helper for displaying rating stars for refinement
         * @returns {Function} mustache helper for stars rating rendering
         */
        getStarsRatingFunction() {
            return function () {
                return function (text, render) {
                    const currentValue = render(text);

                    if (Number.isNaN(currentValue)) {
                        return currentValue;
                    }

                    const currentRating = parseInt(currentValue, 10);
                    const stars = [1, 2, 3, 4, 5].reduce((acc, val) => {
                        return acc + '<use href="' + (val <= currentRating ? '#star' : '#star-empty') + '"'
                            + ' y="0" x="' + (val > 1 ? (Math.round(val - 1) * 18) : 0) + '" />';
                    }, '');

                    return '<svg class="b-rating-icon" width="90" height="16" focusable="false">'
                        + stars
                        + '</svg>';
                };
            };
        }

        /**
         * @description Update Panel
         * @emits RefinementsPanel#noresult
         * @param {object} data Update data
         * @returns {void}
         */
        updatePanel(data) {
            this.ref('dialog').attr('aria-busy', 'true');
            this.ref('panel').removeClass(this.prefs().classesActiveLevel + 2).addClass(this.prefs().classesActiveLevel + 1);

            // apply filters and results count only after panel is change back
            this.onDestroy(timeout(() => {
                getJSONByUrl(data.url).then(res => {
                    const param = getUrlParams(data.url);

                    if (Object.keys(res).length && res.count) {
                        this.render(
                            'template',
                            {
                                ...res,
                                starsRating: this.getStarsRatingFunction(),
                                updateUrl: removeParamFromURL(appendParamsToUrl(this.prefs().showAjaxUrl, param), 'ajax')
                            },
                            this.ref('dialog')
                        ).then(() => {
                            this.ref('dialog').addClass(this.prefs().classesDialogOpen);
                            this.ref('dialog').attr('aria-busy', 'false');
                            this.defineItems();
                            this.setFocusToFirstItem();
                        });
                    } else {
                        /**
                         * @description Event to
                         * @event RefinementsPanel#noresult
                         */
                        this.emit('noresult');
                    }
                });
            }, this.prefs().itemSwitchTimeout));
        }

        /**
         * @description Open Menu Panel
         * @param {object} data data
         * @returns {Promise<void>} Promise object represents panel rendering result
         */
        openPanel(data) {
            if (data || !this.isOpen) {
                return super.openPanel(data);
            }

            return Promise.resolve();
        }
    }

    return RefinementsPanel;
}
