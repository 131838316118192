import { setCookie, getCookie } from 'widgets/toolbox/cookie';

/**
 * @typedef {typeof import('widgets/Widget').default} Widget
 * @typedef {InstanceType <typeof import('widgets/toolbox/RefElement').RefElement>} RefElement
 * @typedef {InstanceType<ReturnType<typeof import('widgets/global/Button').default>>} button
 */

const GRID_MODE_PRODUCTS = 'products';

const GRID_TYPE_BASIC = 'basicGrid';
const GRID_TYPE_ALTERNATIVE = 'alternativeGrid';

/**
 * @param {Widget} Widget Base widget for extending
 * @returns {typeof RefinementsGridToggle} RefinementsGridToggle class
 */

export default function (Widget) {
    /**
     * @category widgets
     * @subcategory search
     * @class RefinementsGridToggle
     * @augments Widget
     * @classdesc Represents RefinementsGridToggle component
    */

    class RefinementsGridToggle extends Widget {
        prefs() {
            return {
                classesGridButtonActive: 'm-active',
                gridCookieProducts: 'productsGridType',
                gridCookieIdeas: 'ideasGridType',
                gridMode: GRID_MODE_PRODUCTS,
                gridTypeDefault: '',
                ...super.prefs()
            };
        }

        /**
         * @description Set grid type for SM viewport. Called on page load,
         * any refinements applied/unapplied, sorting rule updated etc.
         * @returns {void}
         */
        setGridType() {
            const gridCookieName = this.config.gridMode === GRID_MODE_PRODUCTS
                ? this.prefs().gridCookieProducts
                : this.prefs().gridCookieIdeas;

            const gridType = getCookie(gridCookieName) || this.prefs().gridTypeDefault;

            if (gridType) {
                this.activateGrid(gridType === GRID_TYPE_ALTERNATIVE);
            }
        }

        /**
         * @description handle click on grid toggle icon and emmit parent method
         * @param {button} clickedButton current clicked button
         * @returns {void}
         */
        handleGridClick(clickedButton) {
            if (!clickedButton) {
                return;
            }

            const isAlternativeGrid = clickedButton.data('id') === GRID_TYPE_ALTERNATIVE;

            this.activateGrid(isAlternativeGrid);

            setCookie(
                this.config.gridMode === GRID_MODE_PRODUCTS
                    ? this.prefs().gridCookieProducts : this.prefs().gridCookieIdeas,
                isAlternativeGrid ? GRID_TYPE_ALTERNATIVE : GRID_TYPE_BASIC
            );
        }

        /**
         * @description Activate concrete grid type
         * @param {boolean} isAlternativeGrid is alternative grid switched on
         * @returns {void}
         */
        activateGrid(isAlternativeGrid) {
            this.deactivateGridButtons();

            this.getById(
                isAlternativeGrid ? GRID_TYPE_ALTERNATIVE : GRID_TYPE_BASIC,
                (/** @type {button} */gridButton) => gridButton.activate()
            );

            // Emit toggle alternative grid class on parent widget {ProductListingMgr}
            this.emitWithoutContext('togglerefinementsgrid', isAlternativeGrid);
        }

        /**
         * @description deactivate (remove) grid buttons class names
         * @returns {void}
         */
        deactivateGridButtons() {
            [GRID_TYPE_BASIC, GRID_TYPE_ALTERNATIVE]
                .forEach(gridType => {
                    this.getById(
                        gridType,
                        (/** @type {button} */gridButton) => gridButton.removeClass(
                            this.prefs().classesGridButtonActive
                        )
                    );
                });
        }
    }

    return RefinementsGridToggle;
}
