/**
 * @typedef {typeof import('widgets/Widget').default} Widget
 * @typedef {InstanceType<typeof import('widgets/toolbox/RefElement').RefElement>} refElement
 */

/**
 * @param {Widget} Widget Base widget for extending
 * @returns {typeof LazyloadTrigger} LazyloadTrigger widget
 */
export default function (Widget) {
    /**
     * @class LazyloadTrigger
     * @augments Widget
     */
    class LazyloadTrigger extends Widget {
        prefs() {
            return {
                threshold: 100,
                ...super.prefs()
            };
        }

        init() {
            super.init();

            this.eventEmited = false;

            this.has('self', selfElement => this.initOnScrollListener(selfElement));
        }

        /**
         * @description Add scroll listener to detect lazyload event
         * @param {refElement} selfElement self ref element
         * @returns {void}
         */
        initOnScrollListener(selfElement) {
            if (!selfElement) {
                return;
            }

            this.ev('scroll', () => {
                if (window.innerHeight + this.prefs().threshold > selfElement?.get()?.getBoundingClientRect()?.top) {
                    if (!this.eventEmited) {
                        this.emit('lazyload');

                        this.eventEmited = true;
                    }
                }
            }, window);
        }
    }

    return LazyloadTrigger;
}
