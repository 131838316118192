/**
 * @typedef {InstanceType<typeof import('widgets/toolbox/RefElement').RefElement>} refElement
 */

/**
 * @param BasicForm Base widget for extending
 * @returns NoSearchResultsForm widget
 */
export default function (BasicForm: ReturnType<typeof import('widgets/forms/BasicForm').default>) {
    /**
     * @category widgets
     * @subcategory search
     * @class NoSearchResultsForm
     * @augments BasicForm
     * @classdesc NoSearchResultsForm widget component with next features:
     * 1. This is simplified form with no validation rules applied
     * 2. Has an ability to clear user input by pressing button
     * @property {string} data-widget - Widget name "noSearchResultsForm"
     * @property {string} data-classes-clear-enabled - class to toggle on clear input button
     * @example <caption>Example of NoSearchResultsForm widget usage</caption>
     * <form
     *     action="${URLUtils.url('Search-Show')}"
     *     method="GET"
     *     data-widget="noSearchResultsForm"
     *     data-event-submit="handleSubmit"
     * >
     *     ... form contents
     *     <input data-ref="input" role="combobox" name="q" value="" data-event-input="handleInput" />
     *     <button data-ref="clearButton" data-event-click="clearInput"
     *         type="reset"
     *         title="${Resource.msg('header.search.clear', 'search', null)}"
     *         tabindex="-1"
     *     >
     *         <isinclude template="/common/svg/clear" />
     *     </button>
     *     ... submit button
     * </form>
     */
    class NoSearchResultsForm extends BasicForm {
        prefs() {
            return {
                classesClearEnabled: 'm-visible',
                ...super.prefs()
            };
        }

        /**
         * @description Submit form simplified handler
         * @param {refElement} _el event source element
         * @param {Event} event event instance
         * @returns {void}
         */
        handleSubmit(_el, event) {
            if (!this.ref('input').val()) {
                event.preventDefault();
            }
        }

        /**
         * @description Handle input event into the form field
         * @listens dom#input
         * @returns {void}
         */
        handleInput() {
            this.toggleClearButton(!!this.ref('input').val());
        }

        /**
         * @description Toggle Clear Button based on input value
         * @param {boolean} isInputHasValue - indicates if input has a value
         * @returns {void}
         */
        toggleClearButton(isInputHasValue) {
            this.ref('clearButton').toggleClass(this.prefs().classesClearEnabled, isInputHasValue);
        }

        /**
         * @description Clear input field
         * @returns {void}
         */
        clearInput() {
            const searchInput = this.ref('input');

            searchInput.val('');

            const searchInputField = searchInput.get();

            if (searchInputField) {
                searchInputField.focus();
            }

            this.toggleClearButton(false);
        }
    }

    return NoSearchResultsForm;
}
