import { TWidget } from 'widgets/Widget';
const keyCode = Object.freeze({
    RETURN: 13,
    SPACE: 32,
    UP: 38,
    DOWN: 40
});

/**
 * @param Widget Base widget for extending
 * @returns RefinementsPanelToggle instance
 */
export default function (Widget: TWidget) {
    /**
     * @category widgets
     * @subcategory search
     * @class RefinementsPanelToggle
     * @augments Widget
     * @classdesc Widget, which triggers touch/mobile refinements panel to open/close
     * @property {string} data-widget - Widget name `refinementsPanelToggle`
     * @property {string} data-event-click - Event handler method for click event
     * @property {string} data-event-keydown - Event handler method for keydown event
     * <button
     *      class="b-plp_actions-refinements_toggle b-button"
     *      aria-controls="refinements-panel"
     *      aria-expanded="false"
     *      title="${Resource.msg('refinements.filter', 'search', null)}"
     *      type="button"
     *      data-widget="refinementsPanelToggle"
     *      data-event-click.prevent.stop="handleClick"
     *      data-event-keydown="handleKeydown"
     *      data-tau="refinements_open"
     * >
     *          <isif condition="${pdict.productSearch.hasSelectedFilters}">
     *              ${Resource.msg('refinements.editfilter', 'search', null)}
     *          <iselse/>
     *              ${Resource.msg('refinements.filter', 'search', null)}
     *           </isif>
     * </button>
     * <isinclude template="search/sortOrderMenu" />
     */
    class RefinementsPanelToggle extends Widget {
        /**
         * @description Init widget logic
         * @listens "refinement.panel.close"
         * @listens "refinement.panel.open"
         * @returns {void}
         */
        init() {
            this.eventBus().on('refinement.panel.close', 'closePanel');
            this.eventBus().on('refinement.panel.open', 'openPanel');
        }

        /**
         * @description Focus
         * @returns {void}
         */
        focus() {
            this.ref('self').focus();
        }

        /**
         * @description Show panel
         * @emits "refinement.panel.open"
         * @param {boolean} [focusToLastItem] Focus to last item flag
         * @returns {void}
         */
        showPanel(focusToLastItem) {
            this.openPanel();
            /**
             * @description Event to open refinement panel
             * @event "refinement.panel.open"
             */
            this.eventBus().emit('refinement.panel.open', { focusToLastItem });
        }

        /**
         * @description Handle Click
         * @listens dom#click
         * @returns {void}
         */
        handleClick() {
            // @ts-expect-error ts-migrate(2554) FIXME: Expected 1 arguments, but got 0.
            this.showPanel();
        }

        /**
         * @description Open panel
         * @returns {void}
         */
        openPanel() {
            this.ref('self').attr('aria-expanded', 'true');
        }

        /**
         * @description Close panel
         * @returns {void}
         */
        closePanel() {
            this.focus();
            this.ref('self').attr('aria-expanded', 'false');
        }

        /**
         * @description Keydown Event handler
         * @listens dom#keydown
         * @param {HTMLElement} _ Source of keydown event
         * @param {KeyboardEvent} event  Event object
         */
        handleKeydown(_, event) {
            let preventEventActions = false;

            switch (event.keyCode) {
                case keyCode.UP:
                    this.showPanel(true);
                    preventEventActions = true;

                    break;

                case keyCode.RETURN:
                case keyCode.SPACE:
                case keyCode.DOWN:

                    // @ts-expect-error ts-migrate(2554) FIXME: Expected 1 arguments, but got 0.
                    this.showPanel();
                    preventEventActions = true;

                    break;

                default:
                    break;
            }

            if (preventEventActions) {
                event.preventDefault();
                event.stopPropagation();
            }
        }
    }

    return RefinementsPanelToggle;
}
