/**
 * @typedef {typeof import('widgets/Widget').default} Widget
 */

import { getScrollPosition } from 'widgets/toolbox/scroll';

/**
 * @param {Widget} Widget Base widget for extending
 * @returns {typeof StickyRefinements} StickyRefinements widget
 */
export default function (Widget) {
    /**
     * @category widgets
     * @subcategory search
     * @class StickyRefinements
     * @augments Widget
     * @classdesc StickyRefinements helper.
     */
    class StickyRefinements extends Widget {
        init() {
            super.init();

            const selfEl = this.ref('self').get();
            const stickyContentEl = this.ref('stickyContent').get();

            let lastScrollPosition = getScrollPosition();

            if (selfEl && stickyContentEl) {
                this.ev('scroll', () => {
                    const curentScrollPosition = getScrollPosition();
                    const positionDelta = curentScrollPosition - lastScrollPosition;

                    if (positionDelta > 0) {
                        if (stickyContentEl.offsetTop > 0) {
                            stickyContentEl.scrollTop += positionDelta;
                        }
                    } else if (stickyContentEl.offsetTop < stickyContentEl.scrollTop) {
                        stickyContentEl.scrollTop += positionDelta;
                    }

                    lastScrollPosition = curentScrollPosition < 0 ? 0 : curentScrollPosition;
                }, window);
            }
        }
    }

    return StickyRefinements;
}
