/**
 * @typedef {ReturnType<typeof import('widgets/search/RefinementsPanelToggle').default>} RefinementsPanelToggleBase
 */

/**
 * @description Extends RefinementsPanelToggle implementation
 * @param {RefinementsPanelToggleBase} RefinementsPanelToggleBase Base widget for extending
 * @returns {typeof RefinementsPanelToggle} RefinementsPanelToggle extending widget
 */
export default function (RefinementsPanelToggleBase) {
    class RefinementsPanelToggle extends RefinementsPanelToggleBase {
        init() {
            super.init();

            this.initGlobalEvents();
        }

        /**
         * @description inits global events
         */
        initGlobalEvents() {
            this.eventBus().on('refinement.panel.show', 'showPanel');
        }
    }

    return RefinementsPanelToggle;
}
