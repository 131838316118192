import { TWidget } from 'widgets/Widget';

const keyCode = Object.freeze({
    RETURN: 13,
    SPACE: 32
});

/**
 * @param Widget Base widget for extending
 * @returns Refinement widget
 */
export default function (Widget: TWidget) {
    /**
     * @category widgets
     * @subcategory search
     * @class Refinement
     * @augments Widget
     * @classdesc Represents Refinement component with next features:
     * 1. Support keyboard navigation for accessibility
     * 2. Support all typo of refinement
     * 3. Use "update" and "uncheck" event to handle changes used parent methods
     * 4. Using mustache template for dynamic rendering
     *
     * @property {string} data-widget - Widget name `refinement`
     * @property {string} data-widget-event-click - Event listener to call Parent's widget method
     * @property {string} data-event-click - Event listener method `handleClick` for click event on widget
     * @property {string} data-attr-id - attribute id
     * @property {string} data-attr-value - attribute value for on price refinement
     * @property {string} data-value-from - from value for price refinement
     * @property {string} data-value-to - to value for price refinement
     * @property {string} data-event-keydown - keydown handler
     * @property {string} data-widget-event-update="updateRefinementControls"
     * @property {string} data-widget-event-uncheck="uncheckRefinements"
     * @property {string} data-event-click - click handler
     * @property {string} data-checked - checked flag, used for initialization
     * @property {string} [data-prevent-action=false] - prevent any action flag
     * @example <caption>Example of Refinement widget usage</caption>
     * <div
     *     class="b-refinement_link"
     *     role="menuitem"
     *     aria-checked="false"
     *     aria-disabled="false"
     *     data-widget="refinement"
     *     data-href="{{url}}"
     *     data-event-keydown.prevent="handleKeydown"
     *     data-widget-event-update="updateView"
     *     data-event-click.prevent="changeState"
     *     data-prevent-action="{{${'#'}selected}}true{{/selected}}{{^selected}}false{{/selected}}"
     *     title="{{${'#'}selected}}${Resource.msg('msg.assistive.selected.text', 'common', null)}{{/selected}} {{title}}"
     *     tabindex="0"
     *     {{${'#'}selected}}
     *         data-tau="refinements_option_selected"
     *     {{/selected}}
     * >
     *     <span data-tau="refinements_option_name">
     *         {{displayValue}} {{${'#'}hitCount}}({{hitCount}}){{/hitCount}}
     *     </span>
     * </div>
     */
    class Refinement extends Widget {
        selected = false;

        attrId = '';

        value = '';

        min = '';

        max = '';

        prefs() {
            return {
                attrRefinement: 'data-refinement',
                preventAction: false,
                checked: false,
                attrId: '',
                attrValue: '',
                valueFrom: '',
                valueTo: '',
                ...super.prefs()
            };
        }

        /**
         * @description Toggle check status
         */
        toggleCheck(): void {
            if (this.selected) {
                this.uncheck();
            } else {
                this.check();
            }
        }

        /**
         * @description Check
         */
        check(): void {
            this.ref('self').attr('aria-checked', 'true');
            this.selected = true;
        }

        /**
         * @description Uncheck
         */
        uncheck(): void {
            this.ref('self').attr('aria-checked', 'false');
            this.selected = false;
        }

        /**
         * @description Widget logic initialization
         */
        init(): void {
            this.selected = this.prefs().checked;
            this.attrId = this.prefs().attrId;
            this.value = this.prefs().attrValue;
            this.min = this.prefs().valueFrom;
            this.max = this.prefs().valueTo;
        }

        /**
         * @description Focus
         */
        focus(): void {
            return this.ref('self').focus();
        }

        /**
         * @description Change state
         * @listens dom#click
         * @emits Refinement#uncheck
         * @emits Refinement#update
         * @param  [skipCurrentItemDetermination] Skip Current Item Determination
         */
        changeState(skipCurrentItemDetermination?: boolean): void {
            const param = {};

            if (this.prefs().preventAction) {
                return;
            }

            if (this.attrId === 'price') {
                /**
                 * @description Event to uncheck refinement
                 * @event Refinement#uncheck
                 */
                this.emit('uncheck');
                this.check();
            } else {
                this.toggleCheck();
            }

            // @ts-expect-error ts-migrate(2339) FIXME: Property 'skipCurrentItemDetermination' does not e... Remove this comment to see the full error message
            param.skipCurrentItemDetermination = skipCurrentItemDetermination;
            /**
             * @description Event to update refinement
             * @event Refinement#update
             */
            this.emit('update', param);
        }

        /**
         * @description Is Attribute Refinement
         * @returns Attribute refinement flag
         */
        isAttributeRefinement(): boolean {
            return this.ref('self').hasAttr(this.prefs().attrRefinement);
        }

        /**
         * @description Handle Click
         * @listens dom#click
         */
        handleClick(): void {
            this.changeState(true);
        }

        /**
         * @description Keydown Event handler
         * @listens dom#keydown
         * @param _ Source of keydown event
         * @param event  Event object
         */
        handleKeydown(_: HTMLElement, event: KeyboardEvent): void {
            let preventEventActions = false;

            switch (event.keyCode) {
                case keyCode.RETURN:
                case keyCode.SPACE:
                    this.changeState();
                    preventEventActions = true;
                    break;
                default:
                    break;
            }

            if (preventEventActions) {
                event.preventDefault();
                event.stopPropagation();
            }
        }
    }

    return Refinement;
}
