/**
 * @typedef {ReturnType<typeof import('widgets/search/Refinement').default>} RefinementBase
 */

/**
 * @description Extend Refinement implementation
 * @param {RefinementBase} RefinementBase Base widget for extending
 * @returns {typeof Refinement} Refinement extending widget
 */
export default function (RefinementBase) {
    /**
     * @category widgets
     * @subcategory search
     * @class Refinement
     * @augments RefinementBase
     */
    class Refinement extends RefinementBase {
        prefs() {
            return {
                attrCount: 0,
                ...super.prefs()
            };
        }

        init() {
            super.init();

            if (this.prefs().isRanged) {
                this.value = decodeURIComponent(this.prefs().attrValue);
            }
        }

        /**
         * @description Return count product
         * @returns {number} count product
         */
        getCount() {
            return this.prefs().attrCount;
        }

        /**
         * @description Check
         */
        check() {
            this.ref('self').attr('aria-checked', 'true');
            this.setPref('pendingFilter', true);

            this.selected = true;
        }

        /**
         * @description Uncheck
         */
        uncheck() {
            this.ref('self').attr('aria-checked', 'false');
            this.setPref('pendingFilter', false);

            this.selected = false;
        }
    }

    return Refinement;
}
