/**
 * @typedef {typeof import('widgets/Widget').default} Widget
 */

/**
 * @description QuickViewNavigation implementation
 * @param {Widget} Widget Base widget for extending
 * @returns {typeof QuickViewNavigation} QuickViewNavigation class
 */
export default function (Widget) {
    /**
     * @class QuickViewNavigation
     * @augments Widget
     */
    class QuickViewNavigation extends Widget {
        prefs() {
            return {
                productId: '',
                ...super.prefs()
            };
        }

        init() {
            super.init();

            this.initNavigation();
        }

        /**
         * @description Hides next/previous links according to product tile position in search grid
         * @returns {void}
         */
        initNavigation() {
            this.eventBus().emit('productlistingmgr.execute', productListingMgr => {
                const productId = this.prefs().productId;

                if (productListingMgr.isFirstTile(productId)) {
                    this.has('previous', previous => previous.hide());
                }

                if (productListingMgr.isLastTile(productId)) {
                    this.has('next', next => next.hide());
                }
            });
        }

        /**
         * @description Show previous product tile quick view
         * @returns {void}
         */
        previous() {
            this.eventBus().emit('quickview.show.previous', this.prefs().productId);
        }

        /**
         * @description Show next product tile quick view
         * @returns {void}
         */
        next() {
            this.eventBus().emit('quickview.show.next', this.prefs().productId);
        }
    }

    return QuickViewNavigation;
}
